<template>
  <div class="anketa-wrap">
    <div class="bg"></div>
    <loader style="z-index: 100001; position: relative" />
    <AnketaPage :anketa="appealsDetail" ref="anketa" />
  </div>
</template>
<script>
import { jsPDF } from "jspdf";
import { ref, computed, onMounted } from "vue";
import { watch } from "vue";
import AnketaPage from "./AnketaPage.vue";
import { useRoute } from "vue-router";
import Loader from "@/components/loader.vue";
import { useStore } from "vuex";

export default {
  props: {
    loadActive: Boolean,
    userAnketa: Object,
  },
  components: {
    AnketaPage,
    Loader,
  },

  setup() {
    let doc = null;

    const anketa = ref(null);

    const spinnerActive = ref(false);

    const route = useRoute();

    const active = ref(false);

    const svgHolder = ref(false);

    const store = useStore();

    store.dispatch("appeal/getAllChoices");

    const reloadPdf = () => {
      doc = null;
      doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [1050, 1500],
      });

      doc.setProperties({
        title: "Anketa",
        creator: "Topik",
      });

      doc.addFont("LINESeedKR-Rg.ttf", "LineSeed", "normal");

      doc.addFont(
        "NanumGothicCoding-Regular.ttf",
        "NanumGothicCoding",
        "normal"
      );

      doc.addFont(
        "NanumGothicCoding-Bold.ttf",
        "NanumGothicCodingBold",
        "bold"
      );
      doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
      doc.addFont("Roboto-Medium.ttf", "RobotoMedium", "bold");
      doc.setFont("NanumGothicCoding", "normal");
      doc.setFont("LineSeed", "normal");
      doc.setFont("NanumGothicCodingBold", "bold");
      doc.setFont("Roboto", "normal");
      doc.setFont("RobotoMedium", "bold");

      doc.html(anketa.value.$el, {
        callback: function () {
          showPdf();
        },
        x: 1300,
        y: 0,
      });
    };

    const showPdf = () => {
      window.location.assign(doc.output("bloburl"));
    };

    const loadDeatil = (val) => {
      if (route.query.anketa_id != undefined) {
        store.dispatch("appeal/getAdminAppealsDetail", {
          cb: () => {
            reloadPdf();
          },
          id: val,
        });
        active.value = true;
      } else {
        active.value = false;
      }
    };

    onMounted(() => {
      watch(
        () => route.query.anketa_id,
        (val) => {
          console.log(val);
          loadDeatil(val);
        },
        { immediate: true }
      );
    });

    return {
      doc,
      reloadPdf,
      spinnerActive,
      active,
      appealsDetail: computed(() => store.getters["appeal/adminAppeal"]),
      svgHolder,
      anketa,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  background: grey;
}
</style>
